import i18n from '@src/i18n';

/**
 * Returns value of current language key from a given object.
 * @example
 *   localized({ ja: 'あいうえお', en: 'aiueo' }); # => returns 'あいうえお' for ja
 */
export const localized = (dictionary) => (
  dictionary && (dictionary[i18n.language] || Object.values(dictionary)[0])
);
