import { useRef, Suspense } from "react";
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import SuspenseLoader from '@src/components/ui/SuspenseLoader';
import { AuthProvider } from '@src/providers/AuthProvider';
import Sidebar from '@src/components/ui/Sidebar';
import Header from '@src/components/ui/Header';
import { SidebarProvider } from '@src/providers/SidebarProvider';
import { MainLayoutProvider } from '@src/providers/MainLayoutProvider';
import { ErrorBoundary } from '@sentry/react';
import Fallback from '@src/components/ui/Fallback';

const ApplicationLayout = ({ notFound, ...props }) => {
  const breadcrumbsRef = useRef(null);
  const titleRef = useRef(null);
  const toolbarRef = useRef(null);

  return (
    <AuthProvider>
      <Box display="flex">
        <SidebarProvider>
          <Header titleRef={titleRef} toolbarRef={toolbarRef} />
          <Sidebar />
        </SidebarProvider>

        <MainLayoutProvider breadcrumbsRef={breadcrumbsRef} titleRef={titleRef} toolbarRef={toolbarRef}>
          <Suspense fallback={<SuspenseLoader />}>
            <ErrorBoundary fallback={(props) => <Fallback {...props} />}>
              <Outlet />

              {notFound && 'Not Found'}
            </ErrorBoundary>
          </Suspense>
        </MainLayoutProvider>
      </Box>
    </AuthProvider>
  );
};

export default ApplicationLayout;
