import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { applicationData } from '@src/util/environment';

export default function useRecaptchaToken({ action }) {
  const [token, setToken] = useState("");
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const execute = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (!applicationData.reCAPTCHA) {
        return resolve("");
      }

      if (!window.grecaptcha) {
        setError(t('common:reCAPTCHA.loadError'));
        return reject(t('common:reCAPTCHA.loadError'));
      }

      grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(applicationData.reCAPTCHA, { action });
        setToken(token);
        setTimeout(() => resolve(token), 30);
      });
    });
  }, [action]);

  return { token, action, execute, error };
}
