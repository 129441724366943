import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { applicationData } from '@src/util/environment';
import theme from '@src/theme';
import '@src/sentry';
import '@src/i18n';
import routes from '@src/routes';

if (applicationData.mui) {
  LicenseInfo.setLicenseKey(applicationData.mui);
}

const router = createBrowserRouter(routes);

const BaseApp: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <RouterProvider router={router} />
  </ThemeProvider>
);

export default BaseApp;
