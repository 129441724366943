import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/MenuTwoTone';
import CloseIcon from '@mui/icons-material/CloseTwoTone';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { useBreakpoints } from '@src/util/device';
import { useSidebarContext } from '@src/providers/SidebarProvider';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  width: '100%',
  marginLeft: 0,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${theme.sidebar.width})`,
    marginLeft: `${theme.sidebar.width}`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const Header = ({ titleRef, toolbarRef, ...props }) => {
  const { lgUp } = useBreakpoints();
  const sidebar = useSidebarContext();

  return (
    <StyledAppBar position="fixed" color="white" elevation={2} {...props}>
      <Toolbar>
        <Box flexGrow={1} minWidth={0} ref={titleRef} />

        <Stack direction="row">
          <Box ref={toolbarRef} />
          {lgUp ? null : (<>
            <Divider orientation="vertical" flexItem sx={{ ml: 1.5 }} />
            <IconButton color="primary" edge="end" onClick={sidebar.toggle}>
              {sidebar.isOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </>)}
        </Stack>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;

