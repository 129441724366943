import { localized } from '@src/util/i18n';
import getValue from 'lodash/get';
import dayjs from 'dayjs';

export default class ApplicationModel {
  constructor(attrs = {}) {
    this.reset(attrs);
    this.castDateAttributes('createdAt', 'updatedAt');
  }

  localized(prop) {
    return localized(getValue(this, prop));
  }

  reset(attrs = {}) {
    this._initialAttributes = attrs;

    for (let key in attrs) {
      this[key] = attrs[key];
    }
  }

  castDateAttributes(...attributes) {
    attributes.forEach((attr) => {
      if (this.hasOwnProperty(attr)) {
        if ("string" == typeof this[attr]) {
          // store original string
          this[`__${attr}`] = this[attr];
        } else if (this[`__${attr}`]) {
          // restore from original string
          this[attr] = this[`__${attr}`];
        } else if (this[attr]?.['$d']) {
          // restore from dayjs object
          this[attr] = this[attr]['$d'];
        }

        this[attr] = this[attr] && dayjs(this[attr]);
      }
    });
  }
}

