import ApplicationModel from '@src/models/ApplicationModel';

export default class User extends ApplicationModel {
  authorize(roleName) {
    return this.roles?.find(role => role.name === roleName);
  }

  accessLevel(roleName) {
    return this.authorize(roleName)?.role;
  }
}
