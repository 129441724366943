import { ListSubheader, Box, List, styled } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import SidebarMenuItem from './SidebarMenuItem';
import getMenuItems, { MenuItem } from './items';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react'
import { useAuth } from '@src/providers/AuthProvider';

const MenuWrapper = styled(Box)(({ theme }) => ({
  '.MuiList-root': {
    padding: theme.spacing(1),

    '& > .MuiList-root': {
      padding: theme.spacing(0, 0, 1)
    }
  },
  '.MuiListSubheader-root': {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.white.alpha[50],
    padding: theme.spacing(0, 2.5),
    lineHeight: 1.4,
  },
}));

const SubMenuWrapper = styled(Box)(({ theme }) => ({
  '.MuiList-root': {
    '.MuiListItem-root': {
      padding: '1px 0',
      '.MuiBadge-root': {
        position: 'absolute',
        right: theme.spacing(3.2),

        '.MuiBadge-standard': {
          background: theme.palette.primary.main,
          fontSize: theme.typography.pxToRem(10),
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: theme.palette.primary.contrastText,
        },
      },
      '.MuiButton-root': {
        display: 'flex',
        color: theme.palette.white.alpha[80],
        backgroundColor: 'transparent',
        width: '100%',
        justifyContent: 'flex-start',
        padding: theme.spacing(1.2, 3),
        '.MuiButton-startIcon, .MuiButton-endIcon': {
          transition: theme.transitions.create(['color']),
          '.MuiSvgIcon-root': {
            fontSize: 'inherit',
            transition: 'none',
          },
        },
        '.MuiButton-startIcon': {
          color: theme.palette.white.alpha[30],
          fontSize: theme.typography.pxToRem(20),
          marginRight: theme.spacing(1),
        },
        '.MuiButton-endIcon': {
          color: theme.palette.white.alpha[50],
          marginLeft: 'auto',
          opacity: .8,
          fontSize: theme.typography.pxToRem(20),
        },

        '&.active, &:hover': {
          backgroundColor: theme.palette.white.alpha[10],
          color: theme.palette.white.alpha[100],

          '.MuiButton-startIcon, .MuiButton-endIcon': {
            color: theme.palette.white.alpha[100],
          },
        },
      },

      '&.Mui-children': {
        flexDirection: 'column',

        '.MuiBadge-root': {
          position: 'absolute',
          right: theme.spacing(7),
        },
      },

      '.MuiCollapse-root': {
        width: '100%',

        '.MuiList-root': {
          padding: theme.spacing(1, 0),
        },

        '.MuiListItem-root': {
          padding: '1px 0',

          '.MuiButton-root': {
            padding: theme.spacing(0.8, 3),

            '.MuiBadge-root': {
              right: theme.spacing(3.2),
            },

            '&:before': {
              content: '" "',
              background: theme.palette.white.alpha[100],
              opacity: 0,
              transition: theme.transitions.create(['transform', 'opacity']),
              width: '6px',
              height: '6px',
              transform: 'scale(0)',
              transformOrigin: 'center',
              borderRadius: '20px',
              marginRight: theme.spacing(1.8),
            },

            '&.active, &:hover': {
              '&:before': {
                transform: 'scale(1)',
                opacity: 1,
              },
            },
          },
        },
      },
    },
  },
}));

const renderSidebarMenuItems = ({
  items,
  path
}: {
  items: MenuItem[];
  path: string;
}): JSX.Element => (
  <SubMenuWrapper>
    <List component="div">
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
}): Array<JSX.Element> => {
  const key = item.name;
  const exactMatch = (item.scope || item.link)
    ? !!matchPath(
      {
        path: item.scope || item.link,
        end: true
      },
      path
    )
    : false;

  if (item.items) {
    const partialMatch = (item.scope || item.link)
      ? !!matchPath(
        {
          path: item.scope || item.link,
          end: false
        },
        path
      )
      : false;
    if (item.visible) {
      ev.push(
        <SidebarMenuItem
          key={key}
          active={partialMatch}
          open={partialMatch}
          name={item.name}
          icon={item.icon}
          link={item.link}
          badge={item.badge}
          badgeTooltip={item.badgeTooltip}
        >
          {renderSidebarMenuItems({
            path,
            items: item.items
          })}
        </SidebarMenuItem>
      );
    }
  } else {
    if (item.visible) {
      ev.push(
        <SidebarMenuItem
          key={key}
          active={exactMatch}
          name={item.name}
          link={item.link}
          badge={item.badge}
          badgeTooltip={item.badgeTooltip}
          icon={item.icon}
        />
      );
    }
  }

  return ev;
};

function SidebarMenu() {
  const location = useLocation();
  const { t }: { t: any } = useTranslation('Sidebar', { keyPrefix: 'SidebarMenu' });
  const { user } = useAuth();

  return useMemo(() => (
    getMenuItems(user).map(section => (
      <MenuWrapper key={section.heading}>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              {t(section.heading)}
            </ListSubheader>
          }
        >
          {renderSidebarMenuItems({
            items: section.items,
            path: location.pathname,
          })}
        </List>
      </MenuWrapper>
    ))
  ), [user, location]);
}

export default SidebarMenu;
