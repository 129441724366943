export default ({
  "email": "メールアドレス",
  "E-mail": "メールアドレス",
  "Password": "パスワード",
  "password": "パスワード",
  "Sign In": "ログイン",
  "Sign Out": "ログアウト",
  "Recover password": "パスワードをリセットする",
  "Enter the email used for registration to reset your password.": "登録したメールアドレスを入力してください。",
  "Send instruction to reset password": "<0>パスワードの再設定方法を<1>送信する</1></0>",
  "Forgot password?": "パスワードを忘れた場合はこちら",
  "Try to sign in again?": "ログインはこちら",
  "Change password": "パスワードを更新する",
  "Please enter new password.": "新しいパスワードを入力してください。",
  "New password": "新しいパスワード",
  "Password confirmation": "新しいパスワード（確認用）",
  "Change my password": "パスワードを更新する",
  "Retry": "リトライ",
  "An error occurred.": "エラーが発生しました。",
  "pagination_zero": "{{count}}件",
  "pagination_unpaginated": "{{count}}件",
  "pagination": "{{total}}件中 {{firstOfPage}} - {{lastOfPage}}件目",
  "close": "閉じる",
  "update": "更新",
  "dateFormat": {
    "ymd": "YYYY-MM-DD",
    "ymdhm": "YYYY-MM-DD HH:mm",
    "ymdhmz": "YYYY-MM-DD HH:mm Z",
    "long": "YYYY-MM-DD (dd)",
    "time": "HH:mm",
    "hm": "HH:mm",
  },
  "days": {
    "since": "{{count}}日後",
    "ago": "{{count}}日前",
  },
  "language": {
    "ja": "日本語",
    "en": "英語",
  },
  "ID": "ID",
  "hashedId": "hashed ID",
  "createdAt": "登録日時",
  "updatedAt": "更新日時",
  "status": "ステータス",
  "revisions": "更新履歴",
  "comma": "、",
  "copied": "コピーしました",
  reCAPTCHA: {
    loadError: "reCAPTCHAの読み込みに失敗しました。ページをリロードしてください。",
  },
  dataGrid: {
    actions: "メニュー",
    csv: "CSVをダウンロード",
    share: "URLをコピー",
  },
  GuestBook: {
    entryStatus: {
      ready: "登録完了",
      unready: "未登録",
    },
    checkinStatus: {
      "checked-in": "チェックイン完了",
      "waiting": "未チェックイン",
    },
  }
});
