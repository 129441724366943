import { Suspense } from "react";
import { Outlet } from 'react-router-dom';
import SuspenseLoader from '@src/components/ui/SuspenseLoader';

const UserSessionsLayout = () => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Outlet />
    </Suspense>
  );
};

export default UserSessionsLayout;
