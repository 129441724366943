import ApplicationModel from '@src/models/ApplicationModel';

export default class Pagination extends ApplicationModel {
  constructor(attrs = {}) {
    super(attrs);
    this.page ||= 1;
    this.per ||= 25;
    this.total ||= 0;
    this.totalPages ||= 1;
  }

  get firstOfPage() {
    if (!this.total) { return 0; }
    return ((this.page - 1) * this.per) + 1;
  }

  get lastOfPage() {
    return Math.min(this.page * this.per, this.total);
  }

  get summary() {
    return {
      count: this.total - 0,
      total: this.total?.toLocaleString(),
      firstOfPage: this.firstOfPage?.toLocaleString(),
      lastOfPage: this.lastOfPage?.toLocaleString(),
      context: this.totalPages > 1 ? undefined : 'unpaginated',
    }
  }

  /**
   * Alias for `per`
   */
  get pageSize() {
    return this.per;
  }

  /**
   * Used as an initialState of DataGrid
   */
  get model() {
    const { page, pageSize } = this;
    return { page: page - 1, pageSize };
  }
}
