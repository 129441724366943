import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Radio,
  Popover,
} from '@mui/material';
import i18n from '@src/i18n';
import { useMutationAPI } from '@src/util/api';
import User from '@src/models/User';
import dayjs from 'dayjs';

const supportedLanguages = [
  { locale: 'en', label: 'English' },
  { locale: 'ja', label: '日本語' },
];

const LanguageSwitcher = (props) => {
  const { trigger } = useMutationAPI(
    '/v1/pms/users/me',
    { model: User }
  );

  const switchLanguage = (language: string) => {
    i18n.changeLanguage(language);
    dayjs.locale(language);
    props.onClose();

    trigger({
      method: 'PATCH',
      body: {
        preference: { language },
      },
    });
  };

  return (
    <Popover
      disableScrollLock
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    >
      <List dense disablePadding>
        {supportedLanguages.map(({ locale, label }) => (
          <ListItem
            key={locale}
            onClick={() => switchLanguage(locale)}
            dense
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon>
                <Radio checked={locale == i18n.language} />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Popover>
  );
};

export default LanguageSwitcher;
