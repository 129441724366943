import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import PasswordField from '@src/components/mui-extension/PasswordField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import logo from "@src/assets/logo.svg";
import { Link as RouterLink } from 'react-router-dom';
import { applicationData, csrfToken, csrfParam } from '@src/util/environment';
import { useTranslation } from 'react-i18next';
import { useResetFlash } from '@src/util/router';
import { useRecaptchaToken } from '@src/hooks/recaptcha';
import StandardSnackbar from '@src/components/ui/StandardSnackbar';

const SignIn: React.FC = () => {
  const { flash, params } = applicationData;
  const { t } = useTranslation();
  const reCAPTCHA = useRecaptchaToken({ action: 'LOGIN' });
  useResetFlash();

  const onSubmit = async (e) => {
    e.preventDefault();
    await reCAPTCHA.execute();
    document.querySelector('form').submit();
  };

  return (
    <Container maxWidth="sm" sx={{ mt: { xs: '5vh', sm: '10vh', md: '20vh' } }}>
      <StandardSnackbar severity="error" reason={reCAPTCHA.error} title={reCAPTCHA.error} />

      <Card elevation={4}>
        <CardContent sx={{ px: 4 }}>
          <Box mb={4}>
            <Box
              src={logo}
              alt="Rakuten STAY"
              height="60px"
              component="img"
              display="block"
              mx="auto"
            />
            <Typography component="div" variant="h4" align="center">
              Super Room Solutions
            </Typography>
          </Box>

          {flash?.alert && (
            <Box mb={2}>
              <Alert severity="error">{flash.alert}</Alert>
            </Box>
          )}

          {flash?.notice && (
            <Box mb={2}>
              <Alert severity="info">{flash.notice}</Alert>
            </Box>
          )}

          <Box mb={2}>
            <Typography component="h1" color="textSecondary">{t('Sign In')}</Typography>
          </Box>

          <form action="/users/sign-in" method="post" onSubmit={onSubmit}>
            <input type="hidden" name={csrfParam} value={csrfToken} />
            <input type="hidden" name="user[recaptcha][action]" value={reCAPTCHA.action} />
            <input type="hidden" name="user[recaptcha][token]" value={reCAPTCHA.token} />

            <Box mb={2}>
              <TextField
                type="email"
                defaultValue={params.user?.email}
                autoFocus={!params.user?.email}
                autoComplete="username email"
                name="user[email]"
                label={t("E-mail")}
                fullWidth
                required
                inputProps={{ tabIndex: 1 }}
                InputProps={{ sx: { fontSize: 'body2.fontSize', 'legend': { fontSize: 'caption.fontSize' } } }}
              />
            </Box>

            <Box mb={4}>
              <PasswordField
                autoComplete="current-password"
                autoFocus={!!params.user?.email}
                name="user[password]"
                label={t("Password")}
                fullWidth
                inputProps={{ tabIndex: 2 }}
                InputProps={{ sx: { fontSize: 'body2.fontSize', 'legend': { fontSize: 'caption.fontSize' } } }}
              />
            </Box>

            <Box mb={1} align="center">
              <Button color="rakuten" size="large" variant="contained" type="submit" startIcon={<ExitToApp />}>{t("Sign In")}</Button>
            </Box>
          </form>

        </CardContent>
      </Card>

      <Typography component="p" variant="caption" color="textSecondary" mt={2} align="right"><Link component={RouterLink} to="/users/password/new" color="rakuten.main">{t('Forgot password?')}</Link></Typography>
    </Container>
  );
};

export default SignIn;

