import { createTheme } from '@mui/material/styles';
import { grey } from "@mui/material/colors";
import { alpha, darken } from '@mui/material/styles';
import { isTouchDevice } from '@src/util/device';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const defaultShadows = createTheme({}).shadows;

const themeColors = {
  primary: '#CB3C1D',
  secondary: '#A1A1A1',
  success: '#57CA22',
  warning: '#FFA319',
  error: '#FF1943',
  info: '#33C2FF',
  black: '#223354',
  white: '#252525',
  primaryAlt: '#090A0C',
  trueWhite: '#ffffff'
};

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      tablet: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: [
      '"Noto Sans JP"',
      '"Helvetica Neue"',
      '"Helvetica"',
      '"Hiragino Sans"',
      '"Hiragino Kaku Gothic ProN"',
      '"Arial"',
      '"Yu Gothic"',
      '"Meiryo"',
      'sans-serif'
    ].join(','),
    h1: {
      fontSize: "1.6rem",
    },
    h2: {
      fontSize: "1.4rem",
    },
    h3: {
      fontSize: "1.2rem",
    },
    h4: {
      fontSize: "1rem",
    },
    h5: {
      fontSize: "0.875rem",
    },
    h6: {
      fontSize: "0.75rem",
    },
  },
  shadows: defaultShadows.map(s => s.replace(/0,0,0,/g, '34,51,84,').replace(/0.2/, '0.1').replace(/0.14/, '0.04').replace(/0.12/, '0.02')),
  palette: {
    primary: {
      main: '#478547',
      light: '#69AF69',
      dark: '#356435',
      contrastText: '#fff'
    },
    brand: {
      main: '#00B900',
      light: '#2AC72A',
      dark: '#009500',
      contrastText: '#fff'
    },
    rakuten: {
      main: '#BF0000',
      light: '#E61717',
      dark: '#930A0A',
      contrastText: '#fff'
    },
    secondary: {
      lighter: alpha(themeColors.secondary, 0.1),
      light: alpha(themeColors.secondary, 0.75),
      main: themeColors.secondary,
      dark: darken(themeColors.secondary, 0.2)
    },
    // primary: {
    //   lighter: alpha(themeColors.primary, 0.1),
    //   light: alpha(themeColors.primary, 0.75),
    //   main: themeColors.primary,
    //   dark: darken(themeColors.primary, 0.2)
    // },
    success: {
      lighter: alpha(themeColors.success, 0.1),
      light: alpha(themeColors.success, 0.75),
      main: themeColors.success,
      dark: darken(themeColors.success, 0.2)
    },
    warning: {
      lighter: alpha(themeColors.warning, 0.1),
      light: alpha(themeColors.warning, 0.75),
      main: themeColors.warning,
      dark: darken(themeColors.warning, 0.2)
    },
    error: {
      lighter: alpha(themeColors.error, 0.1),
      light: alpha(themeColors.error, 0.75),
      main: themeColors.error,
      dark: darken(themeColors.error, 0.2)
    },
    info: {
      lighter: alpha(themeColors.info, 0.1),
      light: alpha(themeColors.info, 0.75),
      main: themeColors.info,
      dark: darken(themeColors.info, 0.2)
    },
    white: {
      main: alpha(themeColors.trueWhite, 0.95),
      light: themeColors.trueWhite,
      dark: alpha(themeColors.white, 0.02),
      contrastText: themeColors.black,
      alpha: {
        5: alpha(themeColors.trueWhite, 0.02),
        10: alpha(themeColors.trueWhite, 0.1),
        20: alpha(themeColors.trueWhite, 0.2),
        30: alpha(themeColors.trueWhite, 0.3),
        50: alpha(themeColors.trueWhite, 0.5),
        70: alpha(themeColors.trueWhite, 0.7),
        80: alpha(themeColors.trueWhite, 0.8),
        90: alpha(themeColors.trueWhite, 0.9),
        100: themeColors.trueWhite,
      },
    },
    sky: {
      '50': '#eaf2f2',
      '100': '#cbdfdf',
      '200': '#a8caca',
      '300': '#85b5b5',
      '400': '#6aa5a5',
      '500': '#509595',
      '600': '#498d8d',
      '700': '#408282',
      '800': '#377878',
      '900': '#276767',
      main: '#509595',
      light: '#6AAFAF',
      dark: '#3E7474',
      contrastText: '#fff'
    },
    marine: {
      '50': '#eaeff2',
      '100': '#cbd7df',
      '200': '#a8bcca',
      '300': '#85a1b5',
      '400': '#6a8ca5',
      '500': '#507895',
      '600': '#49708d',
      '700': '#406582',
      '800': '#375b78',
      '900': '#274867',
      main: '#507895',
      light: '#6A92AF',
      dark: '#3E5E74',
      contrastText: '#fff'
    },
    sand: {
      '50': '#f2f0ea',
      '100': '#dfd8cb',
      '200': '#cabfa8',
      '300': '#b5a585',
      '400': '#a5916a',
      '500': '#957e50',
      '600': '#8d7649',
      '700': '#826b40',
      '800': '#786137',
      '900': '#674e27',
      main: '#957E50',
      light: '#AF986A',
      dark: '#74623E',
      contrastText: '#fff'
    },
    sunflower: {
      '50': '#fdfae8',
      '100': '#fbf2c6',
      '200': '#f8e9a1',
      '300': '#f5e07b',
      '400': '#f2da5e',
      '500': '#f0d342',
      '600': '#eece3c',
      '700': '#ecc833',
      '800': '#e9c22b',
      '900': '#e5b71d',
      main: '#ECC813',
      light: '#F0D342',
      dark: '#DAB70B',
      contrastText: '#fff'
    },
    pumpkin: {
      '50': '#fcf0e3',
      '100': '#f7d9ba',
      '200': '#f2bf8c',
      '300': '#eda55e',
      '400': '#e9923c',
      '500': '#e57f19',
      '600': '#e27716',
      '700': '#de6c12',
      '800': '#da620e',
      '900': '#d34f08',
      main: '#E57F19',
      light: '#EB9947',
      dark: '#D47311',
      contrastText: '#fff'
    },
    grey: {
      main: grey[300],
      dark: grey[400],
    },
    background: {
      default: '#f2f5f9',
      light: '#fbfbf9',
      dark: '#11192a',
    },
    action: {
      active: themeColors.black,
      hover: alpha(themeColors.primary, 0.1),
      hoverOpacity: 0.1,
      selected: alpha(themeColors.black, 0.1),
      selectedOpacity: 0.1,
      disabled: alpha(themeColors.black, 0.5),
      disabledBackground: alpha(themeColors.black, 0.02),
      disabledOpacity: 0.38,
      focus: alpha(themeColors.black, 0.1),
      focusOpacity: 0.05,
      activatedOpacity: 0.12
    },
  },
  sidebar: {
    background: darken(themeColors.black, 0.5),
    textColor: themeColors.secondary,
    menuItemColor: '#A3A3A3',
    menuItemColorActive: '#ffffff',
    menuItemBg: themeColors.primaryAlt,
    menuItemBgActive: '#221e1e',
    menuItemIconColor: themeColors.secondary,
    menuItemIconColorActive: '#ffffff',
    menuItemHeadingColor: darken(themeColors.secondary, 0.3),
    boxShadow: '1px 0 0 #413333',
    width: '270px',
  },
  header: {
    height: '80px',
    background: alpha(themeColors.primaryAlt, 0.95),
    boxShadow: '0px 1px 0px' + themeColors.primaryAlt,
    textColor: themeColors.secondary,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        message: {
          padding: '6px 0',
        }
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: !isTouchDevice(),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@keyframes pulse': {
          '0%': {
            transform: 'scale(.85)'
          },
          '20%': {
            transform: 'scale(1.1)'
          },
          '40%': {
            transform: 'scale(.85)'
          },
          '60%': {
            transform: 'scale(1.05)'
          },
          '80%': {
            transform: 'scale(.85)'
          },
          '100%': {
            transform: 'scale(.85)'
          }
        },
        '@keyframes week-pulse': {
          '0%': {
            transform: 'scale(.85)'
          },
          '5%': {
            transform: 'scale(1.1)'
          },
          '10%': {
            transform: 'scale(.85)'
          },
          '15%': {
            transform: 'scale(1.05)'
          },
          '20%': {
            transform: 'scale(.85)'
          },
          '100%': {
            transform: 'scale(.85)'
          }
        },
      },
    },
  },
});

export default theme;

