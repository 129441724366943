import { createContext, useContext, useRef, useState } from 'react';
import { useAppBarHeight } from '@src/util/device';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const Context = createContext({});

const StyledContainer = styled(Container)(({ theme }) => ({
  maxWidth: '100vw',
  [theme.breakpoints.up('lg')]: {
    maxWidth: `calc(100vw - ${theme.sidebar.width})`,
  },
}));

export const MainLayoutProvider: React.FC = ({ breadcrumbsRef, titleRef, toolbarRef, notifyError, ...props }) => {
  const appBarHeight = useAppBarHeight();

  return (
    <StyledContainer component="main" maxWidth={false} sx={{ mt:`${appBarHeight}px` }}>
      <Context.Provider value={{ breadcrumbsRef, titleRef, toolbarRef, notifyError }} {...props} />
      <Box ref={breadcrumbsRef} mb={3} />
    </StyledContainer>
  );
};

export const useMainLayoutContext = () => useContext(Context);

