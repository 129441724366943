import { forwardRef, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Badge,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  badgeClasses,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PowerSettingsNewTwoToneIcon from '@mui/icons-material/PowerSettingsNewTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import LanguageSwitcher from './LanguageSwitcher';
import { Link as RouterLink } from 'react-router-dom';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.white.alpha[100],
    color: theme.palette.getContrastText(theme.palette.white.alpha[100]),
    fontSize: theme.typography.pxToRem(12)
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.white.alpha[10],
  color: theme.palette.white.alpha[70],
  transition: theme.transitions.create(['all']),
  margin: theme.spacing(0, 1),
  '&:hover': {
    background: theme.palette.white.alpha[20],
    color: theme.palette.white.alpha[100]
  },
}));

const StyledBadge = styled(forwardRef((props, ref) => (
  <Badge
    color="success"
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    variant="dot"
    overlap="circular"
    ref={ref}
    {...props}
  />
)))(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    animation: 'week-pulse 4s infinite',
    top: '5%',
    transition: theme.transitions.create(['all']),
  },
  [`& .${badgeClasses.invisible}`]: {
    animation: 'none',
  }
}));

function SidebarFooter() {
  const { t }: { t: any } = useTranslation('Sidebar');
  const [isOpen, setOpen] = useState<boolean>(false);
  const ref = useRef(null);

  const toggleLanguageSwitcher = (): void => {
    setOpen(current => !current);
  };

  return (
    <Box
      height="60px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LightTooltip placement="top" title={t('FAQ')}>
        <StyledBadge invisible={true}>
          <StyledIconButton component={RouterLink} to="https://collaborativeventures.atlassian.net/wiki/spaces/BusinessImprovement/pages/3295182894/SRS">
            <HelpTwoToneIcon fontSize="small" />
          </StyledIconButton>
        </StyledBadge>
      </LightTooltip>

      <LightTooltip placement="top" title={t('Language')}>
        <StyledIconButton ref={ref} onClick={toggleLanguageSwitcher}>
          <LanguageTwoToneIcon fontSize="small" />
        </StyledIconButton>
      </LightTooltip>

      <LanguageSwitcher
        anchorEl={ref.current}
        onClose={toggleLanguageSwitcher}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={isOpen}
      />

      <LightTooltip placement="top" title={t('Sign Out')}>
        <StyledIconButton href={"/users/sign-out"}>
          <PowerSettingsNewTwoToneIcon fontSize="small" />
        </StyledIconButton>
      </LightTooltip>
    </Box>
  );
}

export default SidebarFooter;
