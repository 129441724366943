import { createContext, useContext, useEffect, useState } from 'react';
import { applicationData } from '@src/util/environment';
import { useAPI } from '@src/util/api';
import { useNavigate } from 'react-router-dom';
import User from '@src/models/User';

const Context = createContext({});

const refreshInterval = 1000 * 60 * 30;

export const AuthProvider: React.FC = props => {
  const { user: fallbackData } = applicationData;
  const { data: user, error, ...rest } = useAPI(
    '/v1/pms/users/me',
    { refreshInterval, fallbackData: new User(fallbackData), model: User }
  );
  const [role, setRole] = useState("default")
  const navigate = useNavigate();

  if (error?.status == 404) {
    location.href = '/users/sign-out';
  }

  // Force to refresh session for deprecated sessions
  if (user?.roles && !Array.isArray(user.roles)) {
    location.href = '/users/sign-out';
  }

  const authorize = (roleName) => {
    useEffect(() => {
      if (!user) { return }

      const authorized = user.authorize(roleName);

      if (authorized) {
        setRole(authorized.role);
      } else {
        navigate(user.defaultPage || "/");
      }
    }, [user, roleName]);
  };

  return <Context.Provider value={{ user, role, authorize, ...rest }} {...props} />;
};

export const useAuth = () => useContext(Context);

