import { createContext, useContext, useState } from 'react';

const Context = createContext({});

export const SidebarProvider: React.FC = props => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(current => !current);
  const close = () => setIsOpen(false);

  return <Context.Provider value={{ isOpen, toggle, close }} {...props} />;
};

export const useSidebarContext = () => useContext(Context);

