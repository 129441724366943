import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useBreakpoints } from '@src/util/device';
import logo from '@src/assets/logo-white.svg';
import SidebarFooter from './SidebarFooter';
import SidebarMenu from './SidebarMenu';
import { useSidebarContext } from '@src/providers/SidebarProvider';
import { Link as RouterLink } from 'react-router-dom';
import './i18n';

const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.white.alpha[10],
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  flexShrink: 0,
  width: '82vw',
  maxWidth: theme.sidebar.width,
  [theme.breakpoints.up('md')]: {
    width: theme.sidebar.width,
  },
  '& .MuiDrawer-paper': {
    width: '82vw',
    maxWidth: theme.sidebar.width,
    [theme.breakpoints.up('md')]: {
      width: theme.sidebar.width,
    },
    boxSizing: 'border-box',
    backgroundColor: theme.sidebar.background,
    color: 'white',
  },
}));

const Sidebar = (props) => {
  const { lgUp } = useBreakpoints();
  const sidebar = useSidebarContext();

  return (
    <StyledDrawer
      variant={lgUp ? "persistent" : "temporary"}
      open={lgUp ? true : sidebar.isOpen}
      onClose={sidebar.toggle}
      {...props}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box mx={2} mt={1} align="center" lineHeight={1}>
          <Link component={RouterLink} to="/" underline="none">
            <img src={logo} alt="Rakuten STAY" width="70%" />
            <Typography variant="body2" color="white.alpha.70">Super Room Solutions</Typography>
          </Link>
        </Box>

        <StyledDivider sx={{ mt: 2, mx: 2.4 }} />

        <Box component="nav" sx={{ flex: 1, overflow: 'auto', pt: 2 }}>
          <SidebarMenu />
        </Box>

        <StyledDivider />

        <SidebarFooter />
      </Box>
    </StyledDrawer>
  );
};

export default Sidebar;

