import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { applicationData } from '@src/util/environment';
import { en as commonEn, ja as commonJa } from './common';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const resources = {
  en: {
    common: commonEn,
  },
  ja: {
    common: commonJa,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: applicationData.locale,
    fallbackLng: 'en',
    defaultNS: 'common',
    fallbackNS: 'common',
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false
    }
  });

dayjs.locale(i18n.language);
dayjs.extend(utc);
dayjs.extend(timezone);

export default i18n;
