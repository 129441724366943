import type { ReactNode } from 'react';

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import HolidayVillageTwoToneIcon from '@mui/icons-material/HolidayVillageTwoTone';
import MarkAsUnreadTwoToneIcon from '@mui/icons-material/MarkAsUnreadTwoTone';
import ScheduleSendTwoToneIcon from '@mui/icons-material/ScheduleSendTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import EditNoteTwoToneIcon from '@mui/icons-material/EditNoteTwoTone';
import ReportTwoToneIcon from '@mui/icons-material/ReportTwoTone';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import User from '@src/models/User';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
  visible?: boolean;
  scope?: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
  visible: boolean;
}

function visible() {
  return this.items.some(item => (
    item.visible || item.items?.some(i => i.visible)
  ));
}

const getMenuItems = (user: User) => {
  const menuItems: MenuItems[] = [
    {
      heading: 'Dashboard',
      get visible() { return visible.call(this) },
      items: [
        {
          name: 'Home',
          icon: DashboardTwoToneIcon,
          link: '/',
          visible: !!user?.accessLevel("top"),
        },
      ],
    },
    {
      heading: 'Reservation Management',
      get visible() { return visible.call(this) },
      items: [
        {
          name: 'Reservations',
          icon: MenuBookTwoToneIcon,
          link: '/reservations',
          visible: !!user?.accessLevel("reservations"),
          items: [
            {
              name: 'All Reservations',
              link: '/reservations',
              visible: !!user?.accessLevel("reservations"),
            },
            {
              name: 'Staying Reservations',
              link: '/reservations/staying',
              visible: !!user?.accessLevel("reservations"),
            },
            {
              name: 'Unsent Reservations',
              icon: ReportTwoToneIcon,
              link: '/reservations/unsent',
              visible: !!user?.accessLevel("reservations"),
            },
          ],
        },
      ]
    },
    {
      heading: 'Property Management',
      get visible() { return visible.call(this) },
      items: [
        {
          name: 'Properties',
          icon: HolidayVillageTwoToneIcon,
          link: '/properties',
          visible: !!user?.accessLevel("properties"),
        },
      ]
    },
    {
      heading: 'Housekeeping Management',
      get visible() { return visible.call(this) },
      items: [
        {
          name: 'Housekeeping Management',
          icon: CleaningServicesIcon,
          get visible() { return visible.call(this) },
          items: [
            {
              name: 'Shift Recruitment',
              link: '/housekeeping/shift/recruitments',
              visible: !!user?.accessLevel("housekeeping_shift_recruitments"),
            },
            {
              name: 'Shift Request',
              link: '/housekeeping/shift/requests',
              visible: !!user?.accessLevel("housekeeping_shift_requests"),
            },
            {
              name: 'Shift Assignment',
              link: '/housekeeping/shift/assignments',
              visible: !!user?.accessLevel("housekeeping_shift_assignments"),
            },
            {
              name: 'Shift Confirmation',
              link: '/housekeeping/shift/confirmations',
              visible: !!user?.accessLevel("housekeeping_shift_confirmations"),
            },
            {
              name: 'Shift Pattern',
              link: '/housekeeping/shift/patterns',
              visible: !!user?.accessLevel("housekeeping_shift_patterns"),
            },
            {
              name: 'Room Assignment',
              link: '/housekeeping/room/assignments',
              visible: !!user?.accessLevel("housekeeping_room_assignments"),
            },
            {
              name: 'Cleaning Execution',
              link: '/housekeeping/executions',
              visible: !!user?.accessLevel("housekeeping_room_executions"),
            },
          ],
        },
      ]
    },
    {
      heading: 'Contents Management',
      get visible() { return visible.call(this) },
      items: [
        {
          name: 'Message Management',
          icon: EmailTwoToneIcon,
          scope: '/message/',
          visible: !!user?.accessLevel("message_delivery_logs"),
          link: '/message/delivery/logs',
          items: [
            {
              name: 'Message Delivery Logs',
              icon: MarkAsUnreadTwoToneIcon,
              link: '/message/delivery/logs',
              visible: !!user?.accessLevel("message_delivery_logs"),
            },
            {
              name: 'Message Templates',
              icon: EditNoteTwoToneIcon,
              link: '/message/templates',
              visible: !!user?.accessLevel("message_delivery_logs"),
            },
            {
              name: 'Message Schedule',
              icon: ScheduleSendTwoToneIcon,
              link: '/message/schedules',
              visible: !!user?.accessLevel("message_delivery_logs"),
            },
          ],
        },
      ]
    },
      // {
  //   heading: 'Management',
  //   items: [
  //     {
  //       name: 'Users',
  //       icon: AssignmentIndTwoToneIcon,
  //       link: '/extended-sidebar/management/users',
  //       items: [
  //         {
  //           name: 'List',
  //           link: 'management/users/list'
  //         },
  //         {
  //           name: 'User Profile',
  //           link: 'management/users/single'
  //         }
  //       ]
  //     },
  //     {
  //       name: 'Projects',
  //       icon: AccountTreeTwoToneIcon,
  //       link: '/extended-sidebar/management/projects/list'
  //     },
  //     {
  //       name: 'Commerce',
  //       icon: StorefrontTwoToneIcon,
  //       link: '/extended-sidebar/management/commerce',
  //       items: [
  //         {
  //           name: 'Shop',
  //           link: 'management/commerce/shop'
  //         },
  //       ]
  //     },
  //     {
  //       name: 'Invoices',
  //       icon: ReceiptTwoToneIcon,
  //       link: '/extended-sidebar/management/invoices',
  //       items: [
  //         {
  //           name: 'List',
  //           link: 'management/invoices/list'
  //         },
  //       ]
  //     }
  //   ]
  // },
  ];
  return menuItems.filter(s => s.visible);
}

export default getMenuItems;
