import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { applicationData } from './environment';

/**
 * delete flash (from rails) on component unmount.
 */
export const useResetFlash = () => {
  useEffect(() => {
    setTimeout(() => { delete applicationData.flash; });
  }, []);
};

/**
 *
 */
export const useParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return useMemo(() => {
    const params = new URLSearchParams(location.search);
    const navigateToParams = (params, options) => (
      navigate(Array.from(params).length ? decodeURIComponent(`?${params}`) : location.pathname, options)
    );

    return [params, navigateToParams];
  }, [location.search, location.pathname]);
};

/**
 *
 */
export const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};
